.form-section .service-profile-form {
  margin-top: 20px;
  grid-auto-flow: column;
}

.service-profile-inline-form {
  grid-auto-flow: column;
  margin: 10px 0;
}

.service-profile-form .location-field {
}

.service-profile-form .location-field .location-name {
  /*margin-bottom: 12px;*/
}

.service-profile-form .location-field .location-address {
  /*margin-bottom: 12px;*/
}

.form-section .generator-transfer-form {
  margin-top: 20px;
  grid-auto-flow: column;
}
