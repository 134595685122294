/* TODO(yemon): Just temp! Should probably consolidate into Modals.css */
.inline-controls {
  margin-right: 24px;
}

.inline-controls > select {
  margin-left: 8px;
  display: inline-block;
  width: 240px;
}

.options-field-elements label {
  padding: 8px 12px;
  cursor: pointer;
}

.options-field-elements label.no-padding {
  padding: 0;
}

.options-field-elements input[type="checkbox"] {
  cursor: pointer;
}

.options-field-elements .clickable-row {
  cursor: pointer;
}

.options-field-elements .expandable-action > span {
  padding-right: 12px;
}

.options-field-elements .expandable-action .fa-solid {
  font-size: 20px;
}

.options-field-margin {
  margin-bottom: 64px;
}
