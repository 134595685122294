.inquiry-summary-table {
  overflow-x: auto;
}

.inquiry-summary-table .group-heading {
  text-align: center;
  min-width: 550px
}

.inquiry-summary-table .group-heading-small {
  min-width: 110px;
}

.inquiry-summary-table .fill-heading {
  min-width: 400px;
}

.inquiry-summary-table .group-left {
  border-left: 1px solid #a4a4a4;
  padding-left: 8px;
}

.inquiry-summary-table .group-right {
  border-right: 1px solid #a4a4a4;
  /* padding-right: 8px; */
}

.inquiry-summary-table .group-right {
  border-right: 1px solid #a4a4a4;
  padding-right: 4px;
}

.inquiry-summary-table .title-col {
  min-width: 200px;
}

.inquiry-summary-table .target-input {
  max-width: 80px;
  text-align: right;
}

.inquiry-details-table {
  overflow-x: auto;
}
