.form-section .business-type-setup-entry-form {
  margin-top: 20px;
  grid-auto-flow: column;
}

.usage-label {
  margin-bottom: 8px;
}

.usage-label > i.fa-solid {
  width: 28px;
}
